.container {
  position: fixed;
  left: 8px;
  top: 8px;

  padding: 8px 12px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 6px;
}

.availablePages {
  display: flex;
  flex-direction: column;
}

.availablePage {
  &.active {
    font-weight: bold;
  }

  & + & {
    margin-top: 4px;
  }
}
