.topDivider {
  border-top: 1px solid #F1F1F5;
  margin: 24px 0;
}

.actionVariant + .actionVariant {
  margin-top: 8px;
}

.clickLink {
  text-decoration: underline;
}

.submitButton {
  font-size: 14px;
  line-height: 19px;
  padding: 11px 0;

  width: 100%;
  border-radius: 4px;

  margin-top: 24px;

  color: white;
  background-color: #37A599;
}
