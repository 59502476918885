html {
  height: 100%;
  min-height: 100vh;
  background-color: rgb(249 250 251)
}

body {
  margin: 0;
  height: 100%;
  font-family: ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"
}

h1 {
  color: #171725;
}

#root {
  display: flex;
  min-height: 100%;
  flex-direction: column;
  justify-content: center;
  background-repeat: no-repeat;
  padding-top: 0;
  padding-bottom: 0
}

@media (min-width:640px) {
  #root {
    padding-left: 24px;
    padding-right: 24px
  }
}

@media (min-width:1024px) {
  #root {
    padding-left: 32px;
    padding-right: 32px;
  }
}

#root {
  background-image: url("../../public/img/bg.png")
}

.my-font {
  font-family: 'Work Sans'
}

.login-pf-page {
  margin-top: 0
}

@media (min-width: 640px) {
  .login-pf-page {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 400px;
  }

	.login-pf-page-header {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 400px;
  }
}

.login-pf-page-header span {
  text-align: center;
  font-size: 28px;
  line-height: 36px;
  font-weight: 800;
  color: #171725;
}

.login-pf-page-header span:only-child {
  margin-top: 24px;
}

.login-pf-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.card-pf {
  margin-left: auto;
  margin-right: auto;
  margin-top: 32px;
  width: 100%;
  max-width: 368px;
  border-radius: 8px;
  background-color: rgb(255 255 255);
  padding: 24px;
  box-shadow: 0 4px 12px rgba(0,0,0,.1)
}

#kc-page-title {
  margin: 0;
  display: flex
}

#kc-page-title span {
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
  color: #171725;
}

#kc-locale-wrapper {
  margin-left: auto;
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-left: 16px;
}

@media (min-width:640px) {
  #kc-locale-wrapper {
    padding-left: 24px
  }
}

.kc-custom-lang-switcher {
  display: flex;
  width: 64px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between
}

.kc-custom-lang-switcher a {
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  text-decoration-line: none;
  color: rgb(158 158 158)
}

.kc-custom-lang-switcher a.active-lang {
  color: rgb(55 165 153)
}

.lang-divider {
  height: 16px;
  width: 1px;
  background-color: rgb(229 231 235);
}

@media (min-width:640px) {
  .lang-divider {
    display: block
  }
}

.kc-support-link {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: rgb(206 206 206);
}

.kc-support-link a {
  text-decoration-line: underline;
  color: rgb(206 206 206);
}

#kc-social-providers {
  margin-top: 24px;
  margin-bottom: 24px;
}

.login-pf-social-link a {
  box-sizing: border-box;
  display: flex;
  height: 40px;
  width: 320px;
  flex: none;
  flex-grow: 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.login-pf-social-link a > :not([hidden]) ~ :not([hidden]) {
  margin-right: 4px;
  margin-left: 4px;
}

.login-pf-social-link a {
  border-radius: 4px;
  border-width: 1px;
  border-color: rgb(55 165 153);
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 12px;
  padding-right: 12px;
}

.alert {
  margin-top: 8px;
}

.alert-success {
  color: rgb(22 163 74);
}

.alert-warn {
  color: rgb(202 138 4);
}

.alert-info {
  color: rgb(37 99 235);
}

.alert-error {
  color: rgb(220 38 38);
}

.text-center {
  text-align: center;
}
